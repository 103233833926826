
// This is a temporary generated file. Changes to this file will be overwritten eventually!
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../src/components/layout"

// Sections
import BrandAmbassadorHeader from '../src/layouts/BrandAmbassadorHeader/';
import FeaturedMediaSection from '../src/layouts/FeaturedMediaSection/';

const Page = ({ pageContext }) => {
  const {
    page: { seo, pageBuilder, title, parentId },
  } = pageContext

  const layouts = pageBuilder.layouts || []

  const globalLayoutsQuery = useStaticQuery(graphql`
    query {
      allWp {
        nodes {
          globalLayouts {
            globalLayoutsFields {
              promoBarArea {
                ... on WpGlobalLayouts_Globallayoutsfields_PromoBarArea_PromoBar {
                  content
                  fieldGroupName
                  isActive
                }
              }
            }
          }
        }
      }
    }
  `);

  const promoBarData = globalLayoutsQuery.allWp.nodes[0].globalLayouts.globalLayoutsFields.promoBarArea[0];

  return (
    <Layout {...pageBuilder.pageConfiguration} seo={seo} promobar={promoBarData} layoutType="page">
      {
        layouts.map((layout, index) => {
          
              if (layout.fieldGroupName === 'Page_Pagebuilder_Layouts_BrandAmbassadorHeader') {
                  return <BrandAmbassadorHeader 
                  {...layout} 
                    pageTitle={title}
                     parentId={parentId}
                     scrollSnap={pageBuilder.pageConfiguration.fullPageScrollSnap}
                    key={layout.fieldGroupName + index}
                  />;
              }
            

              if (layout.fieldGroupName === 'Page_Pagebuilder_Layouts_FeaturedMediaSection') {
                  return <FeaturedMediaSection 
                  {...layout} 
                    pageTitle={title}
                     parentId={parentId}
                     scrollSnap={pageBuilder.pageConfiguration.fullPageScrollSnap}
                    key={layout.fieldGroupName + index}
                  />;
              }
            
        })
      }

    </Layout>
  )
}

export default Page
  